import { TNRequestNames } from '@utils/request'

export const REQUEST_CASE_ORIGIN_CODE_WEB = Number(
	process.env.REQUEST_CASE_ORIGIN_CODE_WEB
)

export const ATTACHMENT_HEADER = {
	contentType: 'multipart/form-data'
}

export const ATTACHMENT_REQUEST_FIELDS_NAME = {
	file: 'file',
	incidentId: 'incident_id',
	numericTransactionId: 'numeric_transaction_id'
}

export enum RequestStatusEnum {
	OPEN = 'open',
	CLOSED = 'closed',
	PROCESSING = 'processing',
	WAITING_FOR_PAYMENT = 'waitingForPayment'
}

export const RequestStatusDict = {
	0: RequestStatusEnum.OPEN,
	1: RequestStatusEnum.PROCESSING,
	2: RequestStatusEnum.CLOSED
}

export const RequestStatusReverseDict = {
	[RequestStatusEnum.OPEN]: 0,
	[RequestStatusEnum.PROCESSING]: 1,
	[RequestStatusEnum.CLOSED]: 2
}

export const processingSubStatus = [2, 3, 4]

export enum RequestConsentSourceEnum {
	MY_ACCOUNT = 'MY_ACCOUNT', // Mon Dossier
	PORTAL_OF_ELECTED = 'PORTAL_OF_ELECTED'
}

export const RequestConsentSourceDict = {
	717600003: RequestConsentSourceEnum.MY_ACCOUNT,
	717600004: RequestConsentSourceEnum.PORTAL_OF_ELECTED
}

export const RequestConsentSourceReverseDict = {
	[RequestConsentSourceEnum.MY_ACCOUNT]: 717600003,
	[RequestConsentSourceEnum.PORTAL_OF_ELECTED]: 717600004
}

export enum RequestCategoryEnum {
	PROOF_OF_RESIDENCY = 102720000,
	PROOF_OF_BIRTH = 102720001,
	PROOF_OF_PURCHASE = 102720002,
	NOTICE_OF_CONTRIBUTION = 102720003,
	PAPER_FORM = 102720004,
	IDENTITY_DOCUMENT = 102720005,
	PHOTOGRAPHIC_EVIDENCE = 102720006,
	TECHNICAL_SPECIFICATION = 102720007,
	SUBMISSION = 102720008,
	TECHNICAL_PLAN = 102720009,
	OTHER = 102720010,
	EXPERT_REPORT = 102720011,
	PROXY = 102720012,
	PROOF_OF_INSURANCE = 102720013,
	PERMIT_MELCCFP = 102720014,
	COMPETENCE_CERTIFICATE = 102720015,
	REGISTRATION = 102720016
}

export const requestCategoryDic = {
	eighthInput: RequestCategoryEnum.PROOF_OF_BIRTH,
	proofOfBirth: RequestCategoryEnum.PROOF_OF_BIRTH,
	seventhInput: RequestCategoryEnum.PROOF_OF_RESIDENCY,
	proofOfResidency: RequestCategoryEnum.PROOF_OF_RESIDENCY,
	tenthInput: RequestCategoryEnum.NOTICE_OF_CONTRIBUTION,
	ninthInput: RequestCategoryEnum.PROOF_OF_PURCHASE,
	eleventhInput: RequestCategoryEnum.PROOF_OF_RESIDENCY,
	proofOfPurchase: RequestCategoryEnum.PROOF_OF_PURCHASE,
	paperForm: RequestCategoryEnum.PAPER_FORM,
	identityDocument: RequestCategoryEnum.IDENTITY_DOCUMENT,
	photographicEvidence: RequestCategoryEnum.PHOTOGRAPHIC_EVIDENCE,
	technicalSpecification: RequestCategoryEnum.TECHNICAL_SPECIFICATION,
	submission: RequestCategoryEnum.SUBMISSION,
	technicalPlan: RequestCategoryEnum.TECHNICAL_PLAN,
	other: RequestCategoryEnum.OTHER,
	expertReport: RequestCategoryEnum.EXPERT_REPORT,
	proxy: RequestCategoryEnum.PROXY,
	proofOfInsurance: RequestCategoryEnum.PROOF_OF_INSURANCE,
	permitMELCCFP: RequestCategoryEnum.PERMIT_MELCCFP,
	competenceCertificate: RequestCategoryEnum.COMPETENCE_CERTIFICATE,
	registration: RequestCategoryEnum.REGISTRATION
}

export const requestCategories = {
	eighthInput: 'eighthInput',
	seventhInput: 'seventhInput',
	tenthInput: 'tenthInput',
	ninthInput: 'ninthInput',
	eleventhInput: 'eleventhInput',
	proofOfPurchase: 'proofOfPurchase',
	proofOfResidency: 'proofOfResidency',
	proofOfBirth: 'proofOfBirth',
	noticeOfContribution: 'noticeOfContribution',
	paperForm: 'paperForm',
	identityDocument: 'identityDocument',
	photographicEvidence: 'photographicEvidence',
	technicalSpecification: 'technicalSpecification',
	submission: 'submission',
	technicalPlan: 'technicalPlan',
	other: 'other',
	expertReport: 'expertReport',
	proxy: 'proxy',
	curriculumVitae: 'curriculumVitae',
	lettreDePrésentation: 'lettreDePrésentation',
	proofOfInsurance: 'proofOfInsurance',
	permitMELCCFP: 'permitMELCCFP',
	competenceCertificate: 'competenceCertificate',
	registration: 'registration'
}

export const fileInputList = [
	'tenthInput',
	'eighthInput',
	'seventhInput',
	'ninthInput',
	'eleventhQuestion',
	'heatingSystemBillInput',
	'electricityBillInput',
	'oilBillInput',
	'invoiceOfContractorsInput'
]
// mapping : customCategoryName -> incident_type_id
// TODO: change incident_id to incident_type_id
// LANDSCAPING_AND_MAINTENANCE = 'c783809e-c63a-e911-a98a-000d3af499cf'

export enum RequestPartialsEnum {
	EMAILS = 'emails',
	COMMENTS = 'comments',
	CONSENTS = 'consents',
	HISTORY = 'history',
	ATTACHMENTS = 'attachments',
	FORM = 'form',
	NUMERIC_TRANSACTION = 'numericTransactions'
}
// The reasons why a user would like to cancel a request
export enum CancelRequestReason {
	REQUEST_NOT_VALID = 717600000,
	PROBLEM_SOLVED = 717600001,
	OTHER = 717600002
}

/*
	This values come from RESO and
	keys are relate to RequestConfigs's properties. 
*/
export const REQUEST_CONFIGS_KEYS = {
	unknown: '_UNKNOWN_VALUE_',
	yes: '717600000',
	no: '717600001',
	signalingIntervention: {
		add: '717600000',
		repair: '717600001',
		modification: '717600002',
		obstruction: '717600003'
	},
	signalingType: {
		traffic: '717600000',
		parks: '102720000',
		worksite: '102720001',
		handicap: '102720002',
		other: '717600001'
	},
	newBin: {
		newAddress: '717600000',
		additionalBin: '717600001',
		volumeChange: '717600002'
	},
	binType: {
		recycling: '717600000',
		compost: '717600001',
		garbage: '717600002',
		brokenBinGarbage: '102720000'
	},
	sewerIntervention: {
		collapsed: '102720001',
		noisy: '717600003',
		moved: '717600000',
		deteriorated: '717600002',
		missing: '717600001',
		recessed: '102720000'
	},
	placeType: {
		sidewalk: '717600000',
		bicycleLane: '717600001',
		street: '717600002',
		land: '717600003',
		building: '717600004',
		park: '717600005',
		other: '102720000',
		balcony: '102720001'
	},
	locationPlace: {
		interior: '717600000',
		outside: '717600001'
	},
	uncleanOrigin: {
		accrual: '717600000',
		standingWater: '717600001',
		mold: '717600002',
		unsanitary: '717600003'
	},
	locationLighting: {
		street: '717600000',
		park: '717600001',
		building: '717600002'
	},
	lightingIntervention: {
		addition: '717600000',
		repair: '717600001',
		modification: '717600002'
	},
	property: {
		private: '717600001',
		city: '717600000'
	},
	sector: {
		residential: '717600000',
		institution: '717600001',
		industry: '717600002',
		city: '717600003'
	},
	housing: {
		sevenAndLess: '717600000',
		eightAndMore: '717600001',
		others: '717600002'
	},
	natureCollecting: {
		leaves: '717600000',
		fir: '717600001',
		waste: '717600002',
		electronic: '717600003',
		appliances: '717600004',
		bulky: '102720000'
	},
	municipal: {
		taxBill: '717600000',
		payment: '717600001',
		pool: '717600002',
		other: '717600003'
	},
	intervention: {
		police: '717600000',
		signage: '717600001'
	},
	equipment: {
		donationBox: '717600000',
		heatPump: '717600001',
		foyer: '717600002',
		cookingAppliance: '717600003',
		privacyTrellis: '717600004',
		carShelter: '717600006',
		other: '717600005'
	},
	clothDiapers: {
		firstOption: '0',
		secondOption: '1'
	},
	sustainableHygieneForWhom: {
		firstOption: '588640000',
		secondOption: '588640001'
	},
	sustainableHygieneSituation: {
		firstOption: '588640000',
		secondOption: '588640001'
	},
	sustainableHygieneCategory: {
		firstCat: '588640000',
		secondCat: '588640001',
		thirdCat: '588640002'
	},
	rainwaterProgramList: '717600003',
	treeDonationList: '717600014',
	emergencyType: '717600005',
	snowRemovalBy: {
		citizen: '102720000',
		entrepreneur: '102720001'
	},
	homeType: {
		singleFamily: '588640000',
		twinned: '588640001',
		duplex: '588640002',
		mobile: '588640003',
		triplex: '588640004',
		inRows: '588640005',
		building: '588640006'
	},
	systemType: {
		oil: '588640000',
		biEnergetic: '588640001'
	},
	buildingValues: {
		less_than_500k: '588640000',
		between_500k_and_2m: '588640001',
		between_2m_and_5m: '588640002',
		above_5m: '588640003'
	},
	alternativeHeatingSystem: {
		electric: '588640000',
		aerothermal: '588640001',
		solar: '588640002',
		geothermal: '588640003'
	},
	requestConcern: {
		street: '717600000',
		sidewalk: '717600001',
		hydrant: 'none'
	},
	workProgress: {
		notDone: '588640000',
		permitSubmitted: '588640001',
		alreadyDone: '588640002'
	},
	buildingType: {
		heritageBuilding: '588640000',
		calvary: '588640001',
		crossOfTheRoad: '588640002'
	},
	buildingCategory: {
		residential: '588640000',
		commercial: '588640001',
		industrial: '588640002',
		institutional: '588640003'
	},
	buildingLocation: {
		byAdress: '717600000',
		byLot: '717600001'
	},
	plantedTreeLocation: {
		frontYard: '588640000',
		backYard: '588640002',
		sideYard: '588640003',
		shoreEdge: '588640004',
		vacantLand: '588640005'
	},
	requestReason: {
		purchase: '588640001',
		sell: '588640000'
	},
	propertyMeasureUnit: {
		meters: '588640000',
		feet: '588640001'
	},

	propertySurfaceMeasureUnit: {
		squaredMeters: '588640000',
		squaredFeet: '588640001'
	},

	convertedHeatingSystemType: {
		firstOption: '102720000',
		secondOption: '102720001'
	},
	heatingEnergySource: {
		firstOption: '102720000',
		secondOption: '102720001',
		thirdOption: '102720002'
	},
	mainHeatingSystemType: {
		firstOption: '102720000',
		secondOption: '102720001',
		thirdOption: '102720002',
		fourthOption: '102720003',
		fifthOption: '102720004',
		sixthOption: '102720005'
	},
	licenceAttribution: {
		myName: '102720000',
		otherPerson: '102720001'
	},
	waterCounterStatus: {
		inPossession: '102720000',
		notInPossession: '102720001',
		unReadable: '102720002',
		broken: '102720003',
		unPlugged: '102720004'
	},
	typeOfInfestation: {
		grainBugs: '102720000',
		whiteWorms: '102720001',
		spider: '102720002',
		crabgrass: '102720003',
		wildStrawberry: '102720004',
		groundIvy: '102720005',
		dandelion: '102720006',
		plantain: '102720007',
		others: '102720008'
	},
	natureOfInvention: {
		storageOfConstruction: '102720000',
		storageOfAContainer: '102720001',
		positioningOfMachinery: '102720002',
		others: '102720003'
	},
	impactsOnTraffic: {
		sidewalk: '102720000',
		bicycleLane: '102720001',
		parkingOrShoulderLane: '102720002',
		lanesOfTraffic: '102720003',
		reservedLane: '102720004'
	},
	approximateWidthOfObstruction: {
		meters3: '102720000',
		meters3To6: '102720001',
		meters6To9: '102720002',
		meters9To12: '102720003',
		meters12: '102720004'
	},
	locationOfInfestation: {
		inFront: '102720000',
		backYard: '102720001',
		leftSideYard: '102720002',
		rightSideYard: '102720003'
	},
	spaceTobeTreated: {
		treesShrubs: '102720000',
		Lawn: '102720001',
		buildingFoundations: '102720002',
		others: '102720003'
	},
	areaTobeTreated: {
		lessThanOneM: '102720000',
		oneToFiveM: '102720001',
		fiveToTenM: '102720002',
		moreThanTenM: '102720003'
	},
	controlMethods: {
		agronomic: '102720000',
		mechanical: '102720001',
		physical: '102720002',
		biological: '102720003',
		chemicals: '102720004'
	},
	sectorFireHydrantLocation: {
		firstOption: 'AQB11341',
		secondOption: 'AQB18196',
		thirdOption: 'AQB7497',
		fourthOption: 'AQB4678',
		fifthOption: 'AQB2421',
		sixthOption: 'AQB13856',
		seventhOption: 'AQB779',
		eighthOption: 'AQB10522',
		ninthOption: 'AQB3672',
		tenthOption: 'AQB3100'
	},
	heatingSource: {
		electricity: '106000000',
		naturalGas: '106000001',
		propane: '106000002',
		oilFuel: '106000003',
		dualEnergy: '106000004',
		woodPellets: '106000005',
		others: '106000006'
	},
	declarationType: {
		possess: '588640000',
		replaced: '588640001',
		removed: '588640002',
		neverHad: '588640003'
	},
	fireplaceTypeToDeclare: {
		firstOption: '588642000',
		secondOption: '588642001',
		thirdOption: '588642002',
		fourthOption: '588642003',
		fifthOption: '588642004',
		sixthOption: '588642005',
		seventhOption: '588642006',
		eighthOption: '588642007',
		ninthOption: '588642008',
		tenthOption: '588642009',
		eleventhOption: '588642010',
		twelfthOption: '588642011',
		thirteenthOption: '588642012'
	},
	frequencyOfUse: {
		firstOption: '588643000',
		secondOption: '588643001',
		thirdOption: '588643002',
		fourthOption: '588643003',
		fifthOption: '588643004',
		sixthOption: '588643005'
	},
	deviceLocation: {
		firstOption: '588644000',
		secondOption: '588644001',
		thirdOption: '588644002',
		fourthOption: '588644003',
		fifthOption: '588644004'
	},
	isDeviceCertified: {
		firstOption: '588645000',
		secondOption: '588645001',
		thirdOption: '588645002'
	},
	deviceCertifications: {
		firstOption: '109000000',
		secondOption: '109000001'
	},
	deviceReplacementType: {
		firstOption: '588641000',
		secondOption: '588641001',
		thirdOption: '588641002',
		fourthOption: '588641003',
		fifthOption: '588641004',
		sixthOption: '588641005'
	},
	claimNature: {
		snowRemoval: '102720000',
		flooding: '102720001',
		roadWeatheringDamage: '102720002',
		plumberFees: '102720003',
		roadWorkDamage: '102720004',
		carAccident: '102720005',
		bodilyInjury: '102720006',
		trashCollectionDamage: '102720007',
		treeFall: '102720008',
		waterMainBreak: '102720009',
		seizedGoodsDamage: '102720010',
		towingDamage: '102720011',
		policeInterventionDamage: '102720012',
		firefighterDamage: '102720013',
		other: '102720014'
	},
	tenantOrOwner: {
		owner: '102720000',
		tenant: '102720001'
	},
	yearsOlder: {
		equal_and_over_65_years: '588640000',
		between_18_and_64_years_old: '588640001',
		under_18_years: '588640002'
	},
	whatToDo: {
		request_new_annual_permit: '588740000',
		renewal_and_annual_permit: '588740001',
		replace_lost_or_damaged_tag: '588740002'
	},
	gender: {
		male: '103920000',
		female: '103920001'
	},
	weight_unit: {
		lb_unit: '102720000',
		kg_unit: '102720001'
	},
	measurement_unit: {
		meters_unit: '102720000',
		foot_unit: '102720001'
	},
	reasonForNonRenewal: {
		lost_animal: '588640000',
		given_animal: '588640001',
		sold_animal: '588640002',
		death_animal: '588640003',
		other_reason: '588640004'
	},
	replacePermit: {
		lost: '588640000',
		damaged: '588640001'
	},
	selectedDays: {
		all_dates: '588640000',
		week_days: '588640001',
		weekend: '588640002'
	},
	applicanStatus: {
		owner: '588640000',
		ownersAgent: '588640001',
		other: '588640002'
	},
	requestOrigin: {
		newRole: '588640000',
		changingRole: '588640001',
		correctionOfRole: '588640002',
		modificationNotMade: '588640003'
	},
	researchType: {
		address: '588640000',
		cadastralNumber: '588640001',
		matriculeNumber: '588640002'
	},
	requestType: {
		new_request: '102720000',
		modification: '102720001'
	},
	carSharingForWhom: {
		firstOption: '588640000',
		secondOption: '588640001'
	},
	carSharingSubsidyReceived: {
		firstOption: '717600000',
		secondOption: '717600001'
	},
	bikeSharingForWhom: {
		firstOption: '588640000',
		secondOption: '588640002',
		thirdOption: '588640001'
	},
	bikeSharingSubscription: {
		firstOption: '102720000',
		secondOption: '102720001'
	},
	transactionReason: {
		firstParking: '588640000',
		renewParking: '588640001',
		replaceMySticker: '588640002'
	},
	reasonForReplacement: {
		broken: '598640000',
		changeOfVehicle: '598640001',
		dataCorrection: '598640002',
		moving: '598640003',
		movingVehicles: '598640004',
		lostthumbnail: '598640005',
		stolenThumbnail: '598640006',
		otherReason: '598640007'
	},
	whatToDoSwimmingPoolSpaPermit: {
		request_installation: '102720000',
		request_upgrading: '102720001'
	},
	requestAs: {
		individual: '102720000',
		entreprise: '102720001'
	},
	installationTypeSwimmingPoolSpaPermit: {
		firstOption: '102720000',
		secondOption: '102720001',
		thirdOption: '102720002',
		fourthOption: '102720003'
	},
	poolShape: {
		firstOption: '102720000',
		secondOption: '102720001',
		thirdOption: '102720002'
	},
	protectiveEnclosureType: {
		firstOption: '102720000',
		secondOption: '102720001',
		thirdOption: '102720002',
		fourthOption: '102720003',
		fifthOption: '102720004',
		sixthOption: '102720005'
	}
}

export const REQUESTS_IDS = {
	inconvenientLighting: '8af655e4-0736-e911-a989-000d3af49c6c',
	parkLighting: 'dbaf04df-707d-e911-a97f-000d3af490cc',
	buildingLighting: 'e0cfe38d-707d-e911-a97f-000d3af490cc',
	streetLighting: 'd25727f0-0c2f-e911-a98f-000d3af49fdb',
	saggingSewerCover: '025827f0-0c2f-e911-a98f-000d3af49fdb',
	covid: '5484809e-c63a-e911-a98a-000d3af499cf',
	binIncorrectlyReplaced: '30f655e4-0736-e911-a989-000d3af49c6c',
	binLostOrStolen: '32f655e4-0736-e911-a989-000d3af49c6c',
	snowClearingProblem: '9a5727f0-0c2f-e911-a98f-000d3af49fdb',
	snowDepositedOnTheRoadByCitizensOrContractors:
		'a05727f0-0c2f-e911-a98f-000d3af49fdb',
	trafficLight: 'd45727f0-0c2f-e911-a98f-000d3af49fdb',
	pedestrianLight: 'd65727f0-0c2f-e911-a98f-000d3af49fdb',
	treeDonation: '4b14c6ca-c63a-e911-a992-000d3af49fdb'
}

export enum REQUEST_TN_DETAILS_STEPS_NAME {
	MAP = 'MAP',
	DETAILS_1 = 'DETAILS_1',
	DETAILS_2 = 'DETAILS_2',
	DETAILS_3 = 'DETAILS_3',
	DETAILS_4 = 'DETAILS_4',
	DOCUMENTS = 'DOCUMENTS'
}

export const CATEGORIES = {
	REVOKE_CONSENT: 717600009,
	CREATE_CONSENT: 717600008,
	CANCEL_REQUEST: 717600007,
	EMAIL: 717600003,
	PLOT_VERIFICATION: 717600000,
	OPERATIONS_FOLLOW_UP: 717600001,
	SUBCONTRACTOR_INTERVENTION: 717600002,
	ADDITIONAL_INFO_WITH_FOLLOW_UP: 717600004,
	REQUIRED_INTERVENTION: 717600005,
	ADDITIONAL_INFO_WITHOUT_FOLLOW_UP: 717600006
}

export enum IDENTIFIERS_OF_FORM_TYPE {
	CLOTH_DIAPERS_GRANT = 'ecb5becc-e2fe-ec11-82e6-000d3a09d5de',
	WATER_BARREL_GRANT = '5d3ffdf1-e2fe-ec11-82e6-000d3a09d5de',
	GRANT_FOR_OIL_SYSTEM_REPLACEMENT = 'c2fcd71b-e3fe-ec11-82e6-000d3a09d5de',
	HERITAGE_BUILDINGS_REVITALIZATION_PROGRAM = '',
	CRACKED_HOUSES = '',
	TREE_PLANTING_GRANG = '',
	TEMPORARY_PESTICIDE_APPLICATION_PERMIT_FOR_INDIVIDUALS_FORM = '',
	OIL_HEATING_DECLARATION_FORM = '',
	HYDRAULIC_AND_PRESSURE_TESTS = ''
}

export enum IDENTIFIERS_OF_TN_REQUEST_TYPE {
	CLOTH_DIAPERS_GRANT = 'e20a25d7-6dab-ec11-983e-0022486ddaa2',
	WATER_BARREL_GRANT = 'fd864a72-e118-ed11-b83f-000d3a09f295',
	GRANT_FOR_OIL_SYSTEM_REPLACEMENT = '0b78f4f3-e018-ed11-b83f-000d3a09f295',
	HERITAGE_BUILDINGS_REVITALIZATION_PROGRAM = '5b2bf6f6-2577-ed11-81ac-000d3a09f295',
	CRACKED_HOUSES = 'c5d44606-af7f-ed11-81ac-000d3a09ccf4',
	TREE_PLANTING_GRANG = 'acd5c696-dca0-ed11-aad0-000d3a09f295',
	TEMPORARY_PESTICIDE_APPLICATION_PERMIT_FOR_INDIVIDUALS_FORM = '167e327b-b2b7-ed11-b596-000d3af4f456',
	CLAIM_NOTICE = '827016d1-39b9-ed11-b596-000d3af4f456',
	PUBLIC_AUCTIONS = 'df162a89-13c8-ed11-b596-000d3af4fd6d',
	BUILDING_TRANSACTION_REQUEST = '150b31de-68d2-ed11-a7c7-000d3af4f456',
	OIL_HEATING_DECLARATION_FORM = '4dca305d-b9df-ed11-8847-000d3af4fd6d',
	HISTORICAL_ARCHIVE_FORM = '9baf056a-b7ea-ed11-a7c7-000d3af4f456',
	ANNUAL_REGISTRATION_CERTIFICATE_FOR_CONTRACTORS = 'f543bfe5-3eef-ed11-8848-000d3af4fd6d',
	RECRUITMENT_AND_CITIZEN_INVOLVEMENT_FORM = 'RECRUITMENT_AND_CITIZEN_INVOLVEMENT_FORM',
	SELF_RELIEF_WATER_COUNTER_FORM = '46271b3a-ce0e-ee11-8f6e-000d3af4fd6d',
	HYDRAULIC_AND_PRESSURE_TESTS = '808c5d39-6d10-ee11-8f6e-000d3af4fd6d',
	FIRE_HYDRANTS_TEMP_AQUEDUCT_NETWORK_FORM = 'c3895ddf-4d31-ee11-bdf4-000d3af4fd6d',
	FIRE_HYDRANTS_TANK_FILLING_FORM = '7eb3a7bc-6331-ee11-bdf4-000d3af4f895',
	MUNICIPAL_EVALUATION_FORM = '885ae74f-ecc9-ee11-9079-000d3af4fd6d',
	ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS = 'd07d9bc3-2369-ee11-9ae7-000d3af4f456',
	PARKING_PERMIT = 'cd837486-8117-ef11-840b-7c1e52066ee5',
	FIREPLACE_DECLARATION = '0d125192-9eb4-ee11-a569-000d3af4132a',
	PERMIT_OCCUPATION_OF_PUBLIC_HIGHWAY = '55505861-3edb-ee11-904c-000d3af400e5',
	SUSTAINABLE_HYGIENE_PRODUCTS_GRANT = 'da3756fd-32f1-ee11-a1fd-0022486e1836',
	CAR_SHARING_GRANT = '0684da78-5bf3-ee11-a1fd-002248d5a7ad',
	BIKE_SHARING_GRANT = 'a8e96716-3af8-ee11-a1fe-000d3af400e5',
	FIREPLACE_GRANT = '25ef3e45-8917-ef11-840b-7c1e52066ee5',
	SWIMMING_POOL_SPA_PERMIT = '17ae8010-3a45-ef11-a316-6045bdcd5e22'
}

export enum REQUEST_TYPE {
	TN = 'TN',
	PLAIN = 'plain'
}

export const RequestTypeDic = {
	[REQUEST_TYPE.PLAIN]: REQUEST_TYPE.PLAIN,
	[REQUEST_TYPE.TN]: REQUEST_TYPE.TN
}

export const identifierOfFormTypeDic = {
	[TNRequestNames.clothDiapers]: IDENTIFIERS_OF_FORM_TYPE.CLOTH_DIAPERS_GRANT,
	[TNRequestNames.rainwaterBarrel]: IDENTIFIERS_OF_FORM_TYPE.WATER_BARREL_GRANT,
	[TNRequestNames.replacementOilHeatingSystem]:
		IDENTIFIERS_OF_FORM_TYPE.GRANT_FOR_OIL_SYSTEM_REPLACEMENT,
	[TNRequestNames.heritageBuildingsRevitalizationProgram]: '',
	[TNRequestNames.crackedHouses]: '',
	[TNRequestNames.treePlanting]: '',
	[TNRequestNames.temporaryPesticideApplicationPermitForIndividuals]: '',
	[TNRequestNames.claimNotice]: '',
	[TNRequestNames.publicAuctions]: '',
	[TNRequestNames.buildingTransactionRequest]: '',
	[TNRequestNames.oilHeatingDeclaration]: '',
	[TNRequestNames.historicalArchive]: '',
	[TNRequestNames.selfReliefWaterCounter]: '',
	[TNRequestNames.hydraulicAndPressureTests]: '',
	[TNRequestNames.fireHydrantsTempAqueductNetwork]: '',
	[TNRequestNames.fireHydrantsTankFilling]: '',
	[TNRequestNames.municipalEvaluation]: '',
	[TNRequestNames.annualRegistrationCertificateForContractors]: '',
	[TNRequestNames.annualPermitAndTagForAnimals]: '',
	[TNRequestNames.fireplaceDeclaration]: '',
	[TNRequestNames.parkingPermit]: '',
	[TNRequestNames.permitOccupationOfPublicHighway]: '',
	[TNRequestNames.sustainableHygieneProducts]: '',
	[TNRequestNames.carSharing]: '',
	[TNRequestNames.bikeSharing]: '',
	[TNRequestNames.fireplaceGrant]: '',
	[TNRequestNames.swimmingPoolSpaPermit]: ''
}

export const identifierOfRequestTypeDic = {
	[TNRequestNames.clothDiapers]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.CLOTH_DIAPERS_GRANT,
	[TNRequestNames.rainwaterBarrel]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.WATER_BARREL_GRANT,
	[TNRequestNames.replacementOilHeatingSystem]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.GRANT_FOR_OIL_SYSTEM_REPLACEMENT,
	[TNRequestNames.heritageBuildingsRevitalizationProgram]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.HERITAGE_BUILDINGS_REVITALIZATION_PROGRAM,
	[TNRequestNames.crackedHouses]: IDENTIFIERS_OF_TN_REQUEST_TYPE.CRACKED_HOUSES,
	[TNRequestNames.treePlanting]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.TREE_PLANTING_GRANG,
	[TNRequestNames.temporaryPesticideApplicationPermitForIndividuals]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.TEMPORARY_PESTICIDE_APPLICATION_PERMIT_FOR_INDIVIDUALS_FORM,
	[TNRequestNames.claimNotice]: IDENTIFIERS_OF_TN_REQUEST_TYPE.CLAIM_NOTICE,
	[TNRequestNames.publicAuctions]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.PUBLIC_AUCTIONS,
	[TNRequestNames.buildingTransactionRequest]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.BUILDING_TRANSACTION_REQUEST,
	[TNRequestNames.oilHeatingDeclaration]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.OIL_HEATING_DECLARATION_FORM,
	[TNRequestNames.historicalArchive]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.HISTORICAL_ARCHIVE_FORM,
	[TNRequestNames.selfReliefWaterCounter]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.SELF_RELIEF_WATER_COUNTER_FORM,
	[TNRequestNames.hydraulicAndPressureTests]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.HYDRAULIC_AND_PRESSURE_TESTS,
	[TNRequestNames.fireHydrantsTempAqueductNetwork]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.FIRE_HYDRANTS_TEMP_AQUEDUCT_NETWORK_FORM,
	[TNRequestNames.fireHydrantsTankFilling]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.FIRE_HYDRANTS_TANK_FILLING_FORM,
	[TNRequestNames.municipalEvaluation]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.MUNICIPAL_EVALUATION_FORM,
	[TNRequestNames.annualRegistrationCertificateForContractors]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.ANNUAL_REGISTRATION_CERTIFICATE_FOR_CONTRACTORS,
	[TNRequestNames.annualPermitAndTagForAnimals]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS,
	[TNRequestNames.fireplaceDeclaration]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.FIREPLACE_DECLARATION,
	[TNRequestNames.parkingPermit]: IDENTIFIERS_OF_TN_REQUEST_TYPE.PARKING_PERMIT,
	[TNRequestNames.permitOccupationOfPublicHighway]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.PERMIT_OCCUPATION_OF_PUBLIC_HIGHWAY,
	[TNRequestNames.sustainableHygieneProducts]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.SUSTAINABLE_HYGIENE_PRODUCTS_GRANT,
	[TNRequestNames.carSharing]: IDENTIFIERS_OF_TN_REQUEST_TYPE.CAR_SHARING_GRANT,
	[TNRequestNames.bikeSharing]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.BIKE_SHARING_GRANT,
	[TNRequestNames.fireplaceGrant]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.FIREPLACE_GRANT,
	[TNRequestNames.swimmingPoolSpaPermit]:
		IDENTIFIERS_OF_TN_REQUEST_TYPE.SWIMMING_POOL_SPA_PERMIT
}

export const TNRequestResourceDic = {
	replacementOilHeatingSystem: 'incident_fuel_system_replacement_forms',
	rainwaterBarrel: 'incident_water_tank_forms',
	clothDiapers: 'incident_washable_diaper_forms',
	heritageBuildingsRevitalizationProgram: 'incident_patrimonial_building_forms',
	crackedHouses: 'incident_cracked_houses_forms',
	treePlanting: 'incident_planting_forms',
	temporaryPesticideApplicationPermitForIndividuals:
		'incident_temporary_pesticide_application_permit_for_individuals_forms',
	claimNotice: 'incident_claim_notice_forms',
	publicAuctions: 'incident_public_actions_forms',
	buildingTransactionRequest: 'incident_building_transaction_resquest_forms',
	historicalArchive: 'historical_archive_forms',
	selfReliefWaterCounter: 'self_relief_water_counter_forms',
	hydraulicAndPressureTests: 'hydraulic_and_pressure_tests_forms',
	fireHydrantsTempAqueductNetwork: 'fire_hydrants_temp_aqueduct_network_forms',
	fireHydrantsTankFilling: 'fire_hydrants_tank_filling_forms',
	municipalEvaluation: 'municipal_evaluation_forms',
	annualRegistrationCertificateForContractors:
		'annual_registration_certificate_for_contractors',
	annualPermitAndTagForAnimals: 'annual_permit_and_tag_for_animals',
	fireplaceDeclaration: 'fireplace_declaration',
	parkingPermit: 'parking_permit',
	permitOccupationOfPublicHighway: 'permit_occupation_of_public_highway',
	sustainableHygieneProducts: 'sustainable_hygiene_products_forms',
	carSharing: 'car_sharing_forms',
	bikeSharing: 'bike_sharing_forms',
	fireplaceGrant: 'fireplace_grant',
	swimmingPoolSpaPermit: 'swimming_pool_spa_permit'
}

export const INVOICE_STATUS = {
	calculationOfTheAmount: 102720000,
	paid: 102720001,
	waitingForPayment: 102720002,
	notRequired: 102720003
}
